export default {
    root: 'paginator-content',
    content: {
        class: ['paginator-content'],
    },
    first: {
        class: ['paginator-page-button'],
    },
    prev: {
        class: ['paginator-page-button'],
    },
    next: {
        class: ['paginator-page-button'],
    },
    last: {
        class: ['paginator-page-button'],
    },
    current: {
        class: ['paginator-current-page-button'],
    },
    page: {
        class: ['paginator-page-button paginator-page'],
    },
    pages: {
        class: ['paginator-pages'],
    },
};
